import React from "react"
import * as styles from "./post.module.css"

import ContentWrapper from "./content-wrapper"
import { GatsbyImage } from "gatsby-plugin-image"

const Post = ({ title, thumbnail, thumbnailSrc, description, children }) => {

  return (
    <article className={`${thumbnail || `no-image`}`}>
      <div className={styles.headerTitle}>
        {thumbnail && <GatsbyImage
          className={styles.heroImage}
          layout="fullWidth"
          image={thumbnail}
          alt=""
        />
        }
        {thumbnailSrc && <div
          className={styles.heroImage}
          style={{ backgroundImage: `url(${thumbnailSrc})` }}
        />
        }
        <div className={styles.heroContent}>
          <ContentWrapper>
            <header className="post-content-header">
              <h1 className="post-content-title">{title}</h1>
            </header>

            {description && <p className="post-content-excerpt">{description}</p>}
          </ContentWrapper>
        </div>
      </div>
      <ContentWrapper>
        <div className="post-content">
          <div className="post-content-body">{children}</div>
        </div>
      </ContentWrapper>
    </article>
  )
}

export default Post
