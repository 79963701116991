import React from "react"
import { graphql } from "gatsby"
import Post from "../components/post"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { getImage } from "gatsby-plugin-image"
import * as css from './blog-post.module.css'


const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const thumbnail = getImage(post.frontmatter.thumbnail)

  return (
    <Layout bigImage={true} location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className={css.postContainer}>
        <Post
          thumbnail={thumbnail}
          title={post.frontmatter.title}
          description={post.frontmatter.description}
        >
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </Post>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumbnail {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`
